<template>
    <Header />
    <main id="main" v-scroll="() => globScrollAnimation()">
        <RouterView />
    </main>
    <Footer />
    <MobileMenu />

    <simple-modal :window-small="isModalSmall">
        <FeedbackForm v-if="modal.feedback" :postRequest="feedbackPost" :rowsTextarea="5" :showErrorDetail="true" />
        <MarketplaceForm v-if="modal.marketplace" />
        <AdviceForm v-if="modal.advice" />
    </simple-modal>
</template>

<script setup>
import { computed } from "vue";
import { SimpleModal } from "vue3-simple-modal-form";
import { FeedbackForm } from "vue3-usf-feedback-form";
import { useStore } from "vuex";
import { useAnimation } from "@assets/js/animation.js";
import Header from "@components/Header.vue";
import Footer from "@components/Footer.vue";
import MobileMenu from "@components/MobileMenu.vue";
import MarketplaceForm from "@components/MarketplaceForm.vue";
import AdviceForm from "@components/AdviceForm.vue";

var cacheChoiseModal = new Object();
const feedbackPost = import.meta.env.VITE_APP_SERVICES_API_URL + "/post-form";
const $store = useStore();

const { globScrollAnimation } = useAnimation()

const modal = computed(() => {
    const value = $store.state.modal.name;
    if (value) {
        cacheChoiseModal = new Object();
        cacheChoiseModal[value] = true;
    }
    return cacheChoiseModal;
});

const isModalSmall = computed(() => {
    return modal.value.marketplace;
});
</script>

<style lang="sass" scoped>
main
    grid-area: m
</style>