<template>
    <header :class="['header', 'header--selection', 'header--round', { 'header-scroll': isHeaderScroll }]">
        
        <!-- <header-alert>
            Перейти на старую версию сайта <a href="https://old.avantechparts.co.kr/">https://old.avantechparts.co.kr/</a>
        </header-alert> -->

        <div class="header--container">
            <div class="container">
                <nav class="header--navbar uk-navbar" uk-navbar>
                    <router-link class="header--logo uk-navbar-item" to="/" aria-label="Back to Home">
                        <LogoDefault />
                    </router-link>

                    <div class="header--navbar-right uk-navbar-right">
                        <Navbar class="header--menu-bar" />
                        <LanguageBar class="header--lang-bar" />
                        <Hamburger 
                            class="header--hamb" 
                            :toggle="isMenuActive"
                            :launch="isMobileDevice" 
                            @get:toggle="store.commit('default/OPEN_MENU', $event)"
                        />
                    </div>
                </nav>
            </div>
        </div>

        <SelectionBar v-if="locale === 'ru'" :mobile="isMobileDevice" @action:top="isHeaderScroll = !$event" />
    </header>
    <div class="header--fix" v-if="locale === 'ru'" :style="{ height: headerHeight }"></div>
</template>

<script setup>
// import HeaderAlert from "@components/HeaderAlert.vue";

import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useScreenResize } from "@assets/js/screen-resize.js";
import LogoDefault from "@assets/img/svg/logo.svg";
import Navbar from "@components/Navbar.vue";
import LanguageBar from "@components/LanguageBar.vue";
import SelectionBar from "@components/SelectionBar.vue";
import Hamburger from "@components/Hamburger.vue";

const { locale } = useI18n();

const { device } = useScreenResize();
const store = useStore();
const isHeaderScroll = ref(false);
const headerHeight = ref(0);

const isMobileDevice = computed(() => {
    return device.value === "mobile";
});

const isMenuActive = computed(() => {
    return store.state.default.isMenuActive;
});

onMounted(() => {
    const headerEl = document.querySelector(".header--navbar");
    headerHeight.value = headerEl.offsetHeight + "px";
});
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.header
    grid-area: h
    z-index: 100
    position: fixed
    top: 0
    width: 100%
    max-width: 100vw
    border-radius: 15px
    border-top-left-radius: 0
    border-top-right-radius: 0
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3)
    &--container
        max-height: 78px
        background: white
        z-index: 1
        position: relative
        @include lg
            max-height: none
        &:only-child
            border-radius: inherit
        &:not(:only-child)
            & .header--navbar
                border-bottom: 1px solid $color-grey-100
    &--logo
        width: 150px
        padding: 10px 0 4px
        transition: .15s ease-in-out
        position: relative
        svg
            width: 100%
        &:hover
            opacity: .65
        @include lg
            min-height: 70px
            padding: 10px 0 0
            @at-root
                .header-scroll #{&}
                    display: none
        @include md
            @at-root
                .header-scroll #{&}
                    display: flex
    &--navbar
        @include xl
            &-right
                gap: 15px
        @include lg
            display: flex
            flex-direction: column
            &-right
                justify-content: space-between
                width: 100%
                min-height: 48px
        @include md
            flex-direction: row
            justify-content: space-between
            &-right
                width: auto
    &--menu-bar
        gap: 15px
    &--menu-bar, &--lang-bar
        align-items: center
        @include md
            display: none
    &--hamb
        display: none
        @include md
            display: block
</style>

<style lang="sass" static>
@import '@stylebase/media.sass'
.header
    &-container,  &--navbar, &--navbar li > a,  &--logo
        transition: .25s
        -webkit-transform: .25s

.header-scroll .header
    &-container,  &--navbar, &--navbar li > a, &--logo
        min-height: 48px
</style>
