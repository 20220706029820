const Home = () => import(/* webpackChunkName: "home" */ "./pages/Home.vue");
const SelectionPage = () => import(/* webpackChunkName: "selectionpage" */ "./pages/SelectionPage.vue");
const SelectionProduct = () => import(/* webpackChunkName: "selectionproduct" */ "./pages/SelectionProduct.vue");
const StoreMap = () => import(/* webpackChunkName: "storemap" */ "./pages/StoreMap.vue");
const Catalog = () => import(/* webpackChunkName: "catalog" */ "./pages/ProductPage.vue");
const Inform = () => import(/* webpackChunkName: "inform" */ "./pages/InformPage.vue");
const Assurance = () => import(/* webpackChunkName: "assurance" */ "./pages/Assurance.vue");
const CatalogProduct = () => import(/* webpackChunkName: "catalogproduct" */ "./pages/CatalogProduct.vue");
const Partner = () => import(/* webpackChunkName: "partner" */ "./pages/Partner.vue");
const About = () => import(/* webpackChunkName: "about" */ "./pages/About.vue");
const InformItemPage = () => import(/* webpackChunkName: "inform-item" */ "./pages/InformItemPage.vue");

const routes = [
    {
        path: "/:locale",
        children: [
            {
                path: "",
                name: "Home",
                component: Home,
            },
            {
                path: "selection/:selection(parts|wipers|all)",
                name: "SelectionPage",
                component: SelectionPage,
                meta: { titleI18n: "menuSelection" },
            },
            {
                path: "selection/:selection(parts|wipers)/:id",
                name: "SelectionProduct",
                component: SelectionProduct,
                meta: { titleI18n: "menuSelection" },
            },
            {
                path: "catalog",
                name: "Catalog",
                component: Catalog,
                meta: { titleI18n: "menuCatalog" },
            },
            {
                path: "catalog/:product",
                name: "CatalogProduct",
                component: CatalogProduct,
                meta: { titleI18n: "menuCatalog" },
            },
            {
                path: "inform",
                name: "Inform",
                component: Inform,
                meta: { titleI18n: "menuInform" },
            },
            {
                path: "inform/:informId",
                meta: { titleI18n: "menuInform" },
                children: [
                    {
                        path: "",
                        name: "InformId",
                        component: Inform,
                    },
                    {
                        path: ":informLink",
                        name: "InformItemPage",
                        component: InformItemPage,
                    }
                ]
            },
            {
                path: "store",
                name: "StoreMap",
                component: StoreMap,
                meta: { titleI18n: "menuWhereToBuy" },
            },
            {
                path: "assurance",
                name: "Assurance",
                component: Assurance,
                meta: { titleI18n: "menuAssurance" },
            },
            {
                path: "about",
                name: "About",
                component: About,
                meta: { titleI18n: "menuAbout" },
            },
            {
                path: "partners",
                name: "Partner",
                component: Partner,
                meta: { titleI18n: "menuPartners" },
            },
        ],
    },
    {
        path: "/:locale*",
        name: "notFound",
        component: Home,
    },
];

const funcBeforeResolve = async function (to, from, next, langs, i18n) {
    const hrefs = to.href.split("/");
    const { locale, t } = i18n.global;
    let toLocale = "";

    // routes disabled for non-russian locale
    const disabledRoutes = {
        en: ["Inform", "InformId", "Partner", "Assurance", "InformItemPage", "StoreMap", "SelectionPage", "SelectionProduct"],
        kr: ["Inform", "InformId", "Partner", "Assurance", "InformItemPage", "StoreMap", "SelectionPage", "SelectionProduct"],
    };

    for (const element of hrefs) {
        if (element.length !== 0) {
            toLocale = element;
            break;
        }
    }

    toLocale = langs.includes(toLocale) ? toLocale : null;

    if (toLocale) {
        // change locale if URL has different one
        if (locale.value !== toLocale) {
            locale.value = toLocale;
            localStorage.setItem("locale", toLocale);
        }
        
        window.scrollTo({ top: 0, behavior: "instant" });

        document.title = to.meta.titleI18n ? t(to.meta.titleI18n) : "Avantech: автозапчасти, которым можно доверять";

        if (disabledRoutes[toLocale] !== undefined && disabledRoutes[toLocale].includes(to.name)) {
            next({ path: `/${locale.value}` });
        }

        next();
        return 1;
    }

    next({path: `/${locale.value}${to.path}`, query: to.query});
    return 2;
};

export { routes, funcBeforeResolve };
