<template>
    <div :class="['menu', {'menu-open': isMenuActive}]">
        <div class="menu--container">
            <nav class="menu--navbar uk-navbar" uk-navbar>
                <Navbar class="menu--menu-bar navbar-menu-light" :row="true"/>
                <LanguageBar class="menu--lang-bar"/>
                <SvgMenuBackground />
            </nav>
            
            <Footer />
        </div>
    </div>
</template>

<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Navbar from "@components/Navbar.vue";
import LanguageBar from "@components/LanguageBar.vue";
import Hamburger from "@components/Hamburger.vue";
import SvgMenuBackground from "@assets/img/svg/menu-bg.svg";
import Footer from "@components/Footer.vue";

const route = useRoute();
const store = useStore();

const isMenuActive = computed(() => {
    return store.state.default.isMenuActive;
});

watch(() => route.path, () => {
    store.commit('default/OPEN_MENU', false)
})
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
.menu
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    background: white
    opacity: 0
    z-index: -99
    transition: all 0.35s, z-index 0s .35s
    &-open
        opacity: 1
        z-index: 99
        transition: all 0.35s, z-index 0s 0s
    &--header
        display: flex
        justify-content: space-between
        width: 100%
        box-sizing: border-box
    &--container
        display: flex
        flex-direction: column
        align-items: stretch
        height: 100%
        overflow: auto
    &--navbar
        background: $color-primary
        flex: 1 0 auto
        display: flex
        flex-direction: column
        justify-content: space-between
        margin-top: 3.5em
        padding-top: 4em
        svg 
            position: absolute
            width: 100%
            z-index: 0
            opacity: .15
    &--menu-bar
        padding: 60px 0 30px
        gap: .2em
    &--lang-bar
        margin: 0 8px 8px
        border: 3px solid $color-white-100
        border-radius: 7px
        width: fit-content
    &--lang-bar, &--menu-bar
        position: relative
        z-index: 1

</style>