export const en = {
    /** components */
    buttonMore: 'More',
    buttonMarketplace: 'Buy on marketplaces',
    /** end components */
    /** menu */
    menu: 'Menu',
    menuMain: 'Home',
    menuHome: "Avantech Parts Group",
    menuCatalog: "Products",
    menuInfo: "Information",
    menuWhereToBuy: "Where to buy",
    menuAssurance: "Assurance",
    menuPartners: "To partners",
    menuAbout: "About the company",
    menuSelection: "Find a part",
    /** end menu */
    /** modal feedback marketplace */
    modalFeedback: "Feedback",
    marketplaceTitle: "Choose a marketplace",
    marketplaceLinkRetail: "Retail stores on the map",
    /** end modal */
    /** avantech-preview */
    previewText: 'Parts<br />you<br />trust',
    /** category item */
    categorySteering: "Suspension and <br/>steering details",
    categorySteeringMobile: "Suspension and <br/>steering <br>details",
    categoryLamp: "Lamps",
    categoryBelts: "Drive belts",
    categoryAnthers: "Anthers",
    categoryRubber: "Rubber <br/>products",
    categoryPumps: "Fuel pumps",
    categoryFilters: "Filters",
    categoryBrakes: "Brake <br/>system",
    categoryWipers: "Wiper blades",
    categoryWipersMobile: "Wiper blades",
    /** end category item */
    /** about-preview */
    aboutPreview: `<p>A global supplier to the automotive aftermarket.</p>
        Avantech products are supplied to the assembly lines of global automotive manufacturers, to retailers and to technical
        service. For the production of absolutely every detail of Avantech, advanced technologies are used that provide a high level
        quality and trust around the world.`,
    aboutButton: "About the company",
    /** end about-preview */
    /** main-slogan */
    homeSlogan1: "Quality",
    homeSlogan2: "Acceptable",
    homeSlogan3: "price",
    homeSlogan4: "Convenience",
    disclaimerSlogan:
        "Many buyers understand that the price can be lower without <br />degrading the quality of the goods (due to the different labor costs and conditions<br />for business in different countries).",
    disclaimerSloganSpan: "The quality of AVANTECH products is constantly monitored.",
    /** end main-slogan */
    /** postcard */
    postcardTitle: "We listen to our customers",
    postcardText: `<p>You can share your opinion about the products, tell us what difficulties you had, so that you would like to improve or learn about us.</p>
        P.S. - if you cannot find a spare part, you can use the selection service through a consultant at the top of the screen.`,
    postcardFrom: "Avantech team<br/> works for you",
    /** end postcard */
    /** store */
    storeRefText: 'Purchase products online or in stores in your city',
    storeRefLink: 'On the map',
    /** CarouselProduct.vue */
    categorySteeringNote: "Ball. Steering tip. Tie Rod.",
    categorySteeringText: "With the advent of AVANTECH products, car owners have the opportunity not to overpay for suspension and steering parts that meet high OEM requirements and standards. Avantech Parts Group manufactures parts at spare parts manufacturing plants in Malaysia and Taiwan. On the conveyors of these plants, both original and analog parts of world brands are produced.",
    categoryLampNote: "High temperature. Fog. Halogen.",
    categoryLampText: "Produced exclusively in South Korea in a modern robotic production. Every AVANTECH lamp passes a filament geometry test, a gas leak test and a vibration test.",
    categoryBeltsNote: "Wedge. Polyclinic.",
    categoryBeltsText: "Drive belts are designed to drive the ICE attachment and the coolant pump by transmitting torque from the crankshaft pulley. Avantech Parts Group manufactures its products under the AVANTECH brand at a spare parts manufacturing plant in South Korea.",
    categoryAnthersNote: "",
    categoryAnthersText: "In the production of AVANTECH anthers, high-density neoprene is used. They are manufactured at spare parts manufacturing plants that produce both original and analog parts in Taiwan and Malaysia. Production complies with international quality standards ISO/TS 16949.",
    categoryRubberNote: "Silent blocks. Anti-roll bar bushings.",
    categoryRubberText: "AVANTECH rubber products have proven themselves well in the harsh Russian climate with low temperatures. These parts are manufactured at spare parts manufacturing plants located in Taiwan and Malaysia. On the conveyors of these plants, both original and analog parts of world brands are produced. In Russian laboratories, AVANTECH products have successfully passed all tests for tearing, torsion, displacement, which allows us to guarantee compliance with international quality standards.",
    categoryPumpsNote: "",
    categoryPumpsText: "The key partner of Avantech Parts Group for the production of electric fuel pumps is one of the leading manufacturers of fuel equipment for cars in South Korea. The plant's products are also supplied to the assembly lines of world automakers. AVANTECH fuel pumps are manufactured to exacting OEM specifications. The production process is certified in accordance with ISO/TS16949:2002 standards",
    categoryFiltersNote: "Oil. Air. Salon. Fuel.",
    categoryFiltersText: "Clean oil and air for the engine, absence of dust and unpleasant odors in the cabin - these are the main tasks that AVANTECH filters successfully perform. Hundreds of thousands of motorists have already appreciated their high quality, the widest range and attractive prices. AVANTECH offers a wide range of filters for Japanese, Korean, European and American vehicles.", 
    categoryBrakesNote: "Brake pads. Brake discs. Perforated brake discs.",
    categoryBrakesText: "AVANTECH brake pads are manufactured at modern manufacturing plants in South Korea, which are OEM suppliers to the conveyors of the largest car manufacturers, including HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO. <br/><br/>AVANTECH brake discs are manufactured at its partner's factories in Taiwan. Long-term cooperation with OEM-manufacturers confirms the level of quality of the products of this plant and entitles our partner plant to supply original spare parts to the world's leading automakers (Toyota, Honda, Nissan).",
    categoryWipersNote: "Hybrid. Frameless. Frame.",
    categoryWipersText: "AVANTECH wiper blades are represented by all types of designs designed for summer, winter and all-season operation. Anyone can choose a suitable model for both foreign and domestic cars.",
    /** About.vue */
    aboutDescription: "Avantech Parts Group is a global supplier to the automotive aftermarket. Our products are supplied to the assembly lines of global automotive manufacturers, retailers and service stations. For the production of absolutely every part of AVANTECH, advanced technologies are used that provide a high level of quality and trust around the world.",
    aboutQualityTitle: "PRODUCT QUALITY",
    aboutQualityValue: "Avantech Parts Group's products meet the rigorous OE standards and quality standards of the Korean automotive industry. The same basic management principles allow us to set the tone for the aftermarket.",
    aboutResponsableTitle: "OUR RESPONSIBILITY",
    aboutResponsableValue: "Guaranteed safe operation of the car in any conditions. The quality control system of Avantech Parts Group increases the reliability and service life of AVANTECH spare parts compared to analogues to the OEM level. This increases the life of parts and service intervals of vehicles, improves driving comfort.",
    aboutRangeTitle: "A WIDE RANGE OF",
    aboutRangeValue: "Our success is due to the fact that Avantech Parts Group offers a variety of spare parts for most vehicles represented in different regions that meet the needs and expectations of consumers.",
    aboutHistory: "History",
    about2005: "In South Korea, a project was launched to bring high-quality Korean spare parts to the market with an assortment adapted for the buyer and a competitive price. A number of Korean companies - manufacturers of spare parts for conveyors of world automobile brands - participated in the project.",
    about2006: "Avantech Parts Group becomes the winner of the project due to successful marketing research and high competitiveness of products.<br><br>Start of sales of AVANTECH products in Russia. The first to go on sale were wiper blades, anthers for CV joints and a steering rack.",
    about2009: "Expansion of the product range, AVANTECH air and oil filters went on sale.",
    about2010: "Development of the range of auto parts for European cars.<br><br>Start of sales of new groups of auto parts: drive belts, brake pads.",
    about2012: "Start of sales of AVANTECH products in the CIS countries.<br><br>Entering the market of new groups of auto parts: suspension springs, oil and cabin filters.",
    about2014: "Expansion of the product range: chassis parts, high-voltage wires, brake discs.",
    about2015: "AVANTECH warranty program is launched in Russia.<br><br>Start of sales of new groups of auto parts - suspension shock absorbers; ignition coils and AVANTECH fuel pumps.",
    about2017: "Expansion of the product range, AVANTECH lamps went on sale.",
    aboutBrandPanel: "BRAND",
    aboutBrandValue: "The owner of the AVANTECH brand is the manufacturing company Avantech Parts Group Co., Ltd, headquartered in Seoul, the capital of South Korea.",
    aboutProductionTitle: "Production",
    aboutProductionValue: "Since 2006, Avantech Parts Group has been manufacturing a significant part of its products under the AVANTECH brand in factories that produce original parts for automotive giants' conveyors, using their modern production facilities and technological know-how. The geography of production is not limited to this: the company's plants are also located in Malaysia, Indonesia, Taiwan and other countries in Asia and South America.",
    aboutTechTitle: "Hi-tech",
    aboutTechValue: "AVANTECH products are manufactured according to the impeccable quality standards required for original spare parts. The slogan of the AVANTECH brand - Advanced technologies - emphasizes the focus on advanced solutions in the development, production and distribution of spare parts for a wide range of cars from Japan, Korea and Europe. The consumer value of the AVANTECH brand for the end consumer lies in the guarantee of high quality combined with an attractive price level.",
    aboutWorldWeb: "World network",
    aboutFactories: "Avantech Parts Group factories are located in South Korea, Indonesia, Malaysia, Taiwan and other countries in Asia and South America.",
    aboutProductTitle: "Main Products",
    aboutProductValue: "Air and oil filters, chassis parts, electrical equipment, engine parts, brake system parts, wiper blades are the key products of Avantech Parts Group.",
    aboutPartnersTitle: "Company partners",
    aboutPartnersValue: "The key partners of Avantech Parts Group are Korean suppliers of products for KIA and HYUNDAI, which are actively working in the global aftermarket of spare parts and occupy the first place in their sector in the domestic market of South Korea."
};
