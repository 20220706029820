import { reactive, computed, onMounted, onUnmounted, toRefs } from 'vue'

export function useScreenResize() {
    const data = reactive({
        size: null,
        sizes: {
            xxxl: 1600,
            xxl: 1400, 
            xl: 1200,
            lg: 1024,
            md: 768,
            sm: 480,
        },
        device: null,
        devices: {
            laptop: 1200,
            tablet: 1024,
            mobile: 768
        },
        innerWidth: null,
    });

    const {device, size, innerWidth, sizes} = toRefs(data)

    const screen = computed(() => {
        if (data.innerWidth <= data.sizes.lg) {
            return 'mobile';
        }
        return 'default';
    });

    onMounted(() => {
        funcResize();
        if (window) {    
            window.addEventListener('resize', funcResize);
        }
    });

    onUnmounted(() => {
        if (window) {
            window.removeEventListener('resize', funcResize);
        }
    });

    function funcResize() {
        data.size = funcGetAgentDevice(data.sizes, 'xxxl');
        data.device = funcGetAgentDevice(data.devices, 'desktop');
        data.innerWidth = window?.innerWidth;
    }
    
    function funcGetAgentDevice(items, def) {
        let item = def;
        if (window) {
            const width = window.innerWidth;
            for (const name in items) {
               if (width <= items[name]) {
                    item = name;
               }
            }
        }
        return item;    
    }

    return { device, size, innerWidth, screen, sizes }
}
