export const ru = {
    /** components */
    buttonMore: 'Подробнее',
    buttonMarketplace: 'Купить на маркетплейсах',
    /** menu */
    menu: 'Меню',
    menuMain: 'Главная',
    menuHome: "Avantech Parts Group",
    menuCatalog: "Продукция",
    menuInform: "Информация",
    menuWhereToBuy: "Где купить",
    menuAssurance: "Гарантия",
    menuPartners: "Партнерам",
    menuAbout: "О компании",
    menuSelection: "Подбор деталей",
    /** modal feedback marketplace */
    modalFeedback: "Обратная связь",
    marketplaceTitle: "Выберите маркетплейс",
    marketplaceLinkRetail: "Розничные магазины на карте",
    /** avantech-preview */
    previewText: 'Запчасти,<br />которым<br />доверяют',
    /** category item */
    categorySteering: "Детали подвески и <br/>рулевого управления",
    categorySteeringMobile: "Детали <br>подвески и <br/>рулевого <br>управления",
    categoryLamp: "Лампы",
    categoryBelts: "Приводные <br/>ремни",
    categoryAnthers: "Пыльники",
    categoryRubber: "Резинотехнические <br/>изделия",
    categoryPumps: "Топливные <br/>насосы",
    categoryFilters: "Фильтры",
    categoryBrakes: "Тормозная <br/>система",
    categoryWipers: "Щетки <br/>стеклоочистителя",
    categoryWipersMobile: "Щетки <br/>стекло-<br>очистителя",
    /** about-preview */
    aboutPreview: `<p>Глобальный поставщик на рынке автомобильных запасных частей.</p>
                    Продукция Avantech поставляется на сборочные конвейеры мировых автомобильных производителей, в розничную продажу и на станции технического
                    обслуживания. Для производства абсолютно каждой детали Avantech используются передовые технологии, которые обеспечивают высокий уровень
                    качества и доверие по всему миру.`,
    aboutButton: "О компании",
    /** main-slogan */
    homeSlogan1: "Качество",
    homeSlogan2: "Приемлемая",
    homeSlogan3: "цена",
    homeSlogan4: "Удобство",
    disclaimerSlogan: `Многие покупатели понимают, что цена может быть ниже, без <br>снижения качества товара (в силу разной стоимости труда и условий <br>для бизнеса в 
                       разных странах).`,
    disclaimerSloganSpan: "Качество продукции AVANTECH постоянно контролируется.",
    /** postcard */
    postcardTitle: "Мы слушаем наших покупателей",
    postcardText: `<p>Вы можете поделиться своим мнением о продукции,<br/> рассказать какие у вас возникали сложности, чтобы вы хотели<br/> улучшить или узнать о нас.</p>
                   P.S. - если у вас не получается подобрать запчасть, вы можете<br/> воспользоваться услугой подбора через консультанта в<br/> верхней части экрана.`,
    postcardFrom: "<span>Для вас работает</span><br/> команда Avantech",
    /** article card */
    articleCardButton: 'Другие статьи',
    articleTitleBrake: 'Тормозные колодки. Причины скрипа и пыли',
    articleTextBrake: 'Срок службы тормозных колодок напрямую зависит от стиля вождения, от массы автомобиля, от наличия дополнительных электронных систем безопасности и от качества используемых тормозных колодок. В среднем, при эксплуатации автомобиля в смешанном режиме, критический износ передних колодок происходит через 20 - 30 тыс. км., а задних колодок – приблизительно в полтора раза больше, потому как передние тормозные колодки берут на себя наибольшую долю нагрузки.',
    articleTitleWiper: 'Эксплуатация зимних щеток AVANTECH',
    articleTextWiper: 'С наступлением зимы мы часто сталкиваемся с вопросами эксплуатации щеток стеклоочистителя. В линейке продукции AVANTECH представлены зимние щетки стеклоочистителя AVANTECH SNOWGUARD, созданные специально для использования в условиях суровой российской зимы. Многолетний опыт проектирования и производства щеток стеклоочистителя позволяет нам выделить наиболее важные моменты в эксплуатации щеток AVANTECH в зимний период.',
    articleTitleFilt: 'ТЕСТИРОВАНИЕ МАСЛЯНЫХ ФИЛЬТРОВ',
    articleTextFilt: 'Вся продукция Avantech Parts Group проходит тщательное тестирование на соответствие как высоким национальным требованиям стран производства, так и российским стандартам. Детали Avantech сертифицированы в соответствии с требованиями регламента таможенного союза о безопасности колёсных транспортных средств, что подтверждает высокое качество продукции и её безопасность для потребителей, их имущества и окружающей среды.',
    /** store */
    storeRefText: 'Приобретай продукцию в интернете или в магазинах своего города',
    storeRefLink: 'На карте',
    /** CarouselProduct.vue */
    categorySteeringNote: "Шаровая. Рулевой наконечник. Рулевая тяга.",
    categorySteeringText: "С появлением продукции AVANTECH у автовладельцев появилась возможность не переплачивать за детали подвески и рулевого управления, отвечающие высоким требованиям и стандартам OEM. Avantech Parts Group изготавливает детали на заводах-производителях запасных частей в Малайзии и на Тайване. На конвейерах этих заводов производятся как оригинальные, так и аналоговые детали мировых брендов.",
    categoryLampNote: "Высокотемпературные. Противотуманные. Галогенные.",
    categoryLampText: "Производятся исключительно в Южной Корее на современном роботизированном производстве. Каждая лампа AVANTECH проходит контроль геометрии нити накала, тест на утечку газа и тест на устойчивость к вибрации.",
    categoryBeltsNote: "Клиновые. Поликлиновые.",
    categoryBeltsText: "Приводные ремни предназначены для приведения в движение навесного оборудования ДВС и насоса охлаждающей жидкости посредством передачи крутящего момента от шкива коленвала. Avantech Parts Group изготавливает свою продукцию под брендом AVANTECH на заводе-производителе запасных частей в Южной Корее.",
    categoryAnthersNote: "",
    categoryAnthersText: "В производстве пыльников AVANTECH применяется неопрен высокой плотности. Изготавливаются они на заводах-производителях запасных частей, производящих как оригинальные, так и аналоговые детали на Тайване и в Малайзии. Производство соответствует международным системам стандартов качества ISO/TS 16949.",
    categoryRubberNote: "Сайлентблоки. Втулки стабилизатора поперечной устойчивости.",
    categoryRubberText: "Резинотехнические изделия AVANTECH хорошо зарекомендовали себя при эксплуатации в суровом российском климате с пониженными температурами. Изготавливаются эти детали на заводах-производителях запасных частей, расположенных на Тайване и в Малайзии. На конвейерах этих заводов производятся как оригинальные, так и аналоговые детали мировых брендов.. В российских лабораториях продукция AVANTECH успешно прошла все тесты на разрыв, кручение, смещение, что позволяет с гарантировать соответствие международным стандартам качества.",
    categoryPumpsNote: "",
    categoryPumpsText: "Ключевым партнёром Avantech Parts Group по производству электрических топливных насосов является один из ведущих производителей топливного оборудования для автомобилей в Южной Корее. Продукция завода поставляется в том числе на сборочные конвейеры мировых автопроизводителей. Топливные насосы AVANTECH изготовлены в соответствии с жёсткими требованиями OEM. Процесс производства сертифицирован в соответствии со стандартами ISO/TS16949:2002",
    categoryFiltersNote: "Масляные. Воздушные. Салонные. Топливные.",
    categoryFiltersText: "Чистое масло и воздух для двигателя, отсутствие пыли и неприятных запахов в салоне - вот главные задачи, которые с успехом выполняют фильтры AVANTECH. Сотни тысяч автолюбителей уже успели оценить их высокое качество, широчайший ассортимент и привлекательную стоимость. AVANTECH предлагает широкую линейку фильтров для японских, корейских, европейских и американских автомобилей.", 
    categoryBrakesNote: "Тормозные колодки. Тормозные диски. Перфорированные тормозные диски.",
    categoryBrakesText: "Тормозные колодки AVANTECH изготавливаются на современных заводах-производителях в Южной Корее, которые являются ОЕМ-поставщиками на конвейеры крупнейших производителей автомобилей, в том числе HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO. <br/><br/>Тормозные диски AVANTECH производятся на заводах своего партнера на Тайване. Многолетнее сотрудничество с OEM-производителями подтверждает уровень качества продукции данного завода и дает право нашему заводу-партнеру поставлять оригинальные запчасти ведущим мировым автопроизводителям (Toyota, Honda, Nissan).",
    categoryWipersNote: "Гибридные. Бескаркасные. Каркасные.",
    categoryWipersText: "Щетки стеклоочистителя AVANTECH представлены всеми типами конструкций, рассчитанных на летнюю, зимнюю и всесезонную эксплуатацию. Любой желающий сможет подобрать подходящую модель как на зарубежный, так и отечественный автомобиль.",
    /** About.vue */
    aboutDescription: "Avantech Parts Group – глобальный поставщик на рынке автомобильных запасных частей. Наша продукция поставляется на сборочные конвейеры мировых автомобильных производителей, в розничную продажу и на станции технического обслуживания. Для производства абсолютно каждой детали AVANTECH используются передовые технологии, которые обеспечивают высокий уровень качества и доверие по всему миру.",
    aboutQualityTitle: "КАЧЕСТВО ПРОДУКЦИИ",
    aboutQualityValue: "Продукция Avantech Parts Group отвечает строгим стандартам оригинальных производителей и эталонам качества автомобильной промышленности Кореи. Единые базовые принципы управления позволяют нам задавать тон на рынке запасных частей.",
    aboutResponsableTitle: "НАША ОТВЕТСТВЕННОСТЬ",
    aboutResponsableValue: "Гарантированная безопасность эксплуатации автомобиля в любых условиях. Система контроля качества Avantech Parts Group повышает надежность и ресурс запасных частей AVANTECH по сравнению с аналогами до уровня OEM. Это увеличивает срок службы деталей и интервалы обслуживания автомобилей, повышает комфорт от вождения.",
    aboutRangeTitle: "ШИРОКИЙ АССОРТИМЕНТ",
    aboutRangeValue: "Наш успех связан с тем, что Avantech Parts Group предлагает разнообразные запчасти для большинства автомобилей представленных в разных регионах, которые удовлетворяют запросам и ожиданиям потребителей.",
    aboutHistory: "История",
    about2005: "В Южной Корее стартовал проект по выводу на рынок запчастей высокого корейского качества с адаптированным для покупателя ассортиментом и конкурентоспособной ценой. В проекте участвовал ряд корейских компаний - производителей запчастей на конвейеры мировых автомобильных брендов.",
    about2006: "Avantech Parts Group становится победителем проекта за счет успешно проведенных маркетинговых исследований и высокой конкурентоспособности продукции.<br><br>Начало продаж продукции AVANTECH в России. Первыми в продажу поступили щетки стеклоочистителей, пыльники ШРУСов и рулевой рейки.",
    about2009: "Расширение ассортимента продукции, в продажу поступили воздушные и масляные фильтры AVANTECH.",
    about2010: "Развитие ассортимента автозапчастей для европейских автомобилей.<br><br>Старт продаж новых групп автозапчастей: приводные ремни, тормозные колодки.",
    about2012: "Начало продаж продукции AVANTECH в странах СНГ.<br><br>Выход на рынок новых групп автозапчастей: пружин подвески, масляных и салонных фильтров.",
    about2014: "Расширение ассортимента продукции: детали ходовой части, высоковольтные провода, тормозные диски.",
    about2015: "В России запускается гарантийная программа AVANTECH.<br><br>Старт продаж новых групп автозапчастей - амортизаторов подвески; катушек зажигания и топливных насосов AVANTECH.",
    about2017: "Расширение ассортимента продукции, в продажу поступили лампы AVANTECH.",
    aboutBrandPanel: "БРЕНД",
    aboutBrandValue: "Владельцем бренда AVANTECH является компания - производитель Avantech Parts Group Co., Ltd, имеющая головной офис в Сеуле, столице Южной Кореи.",
    aboutProductionTitle: "Производство",
    aboutProductionValue: "С 2006 г. Avantech Parts Group изготавливает значительную часть своей продукции под брендом AVANTECH на заводах, производящих оригинальные детали для конвейеров автомобильных гигантов, используя их современные производственные мощности и технологическое ноу-хау. География производства этим не ограничивается: заводы компании также располагаются в Малайзии, Индонезии, Тайване и в других странах Азии и Южной Америки.",
    aboutTechTitle: "Передовые технологии",
    aboutTechValue: "Продукция AVANTECH производится по безупречным стандартам качества, предъявляемым к оригинальным запасным частям. Лозунг бренда AVANTECH – Advanced technologies – подчеркивает нацеленность на передовые решения в технологиях разработки, производства и дистрибуции запчастей для широкого спектра автомобилей из Японии, Кореи и Европы. Потребительская ценность бренда AVANTECH для конечного потребителя заключается в гарантии высокого качества в сочетании с привлекательным уровнем цен.",
    aboutWorldWeb: "Мировая сеть",
    aboutFactories: "Заводы Avantech Parts Group расположены в Южной Корее, Индонезии, Малайзии, на Тайване и в других странах Азии и Южной Америки.",
    aboutProductTitle: "Основные продукты",
    aboutProductValue: "Воздушные и масляные фильтры, детали ходовой части, электрооборудование, детали двигателя, детали тормозной системы, щетки стеклоочистителей являются ключевыми продуктами компании Avantech Parts Group.",
    aboutPartnersTitle: "Партнеры компании",
    aboutPartnersValue: "Ключевые партнеры Avantech Parts Group - корейские заводы-поставщики продукции для KIA и HYUNDAI, активно работающие на мировом рынке aftermarket запасных частей и занимающие первое место в своём секторе на внутреннем рынке Южной Кореи."
};
