<template>
    <div class="selection__input__container" ref="selectionElement">
        <div class="selection__load" v-if="isLoading">
            <div uk-spinner="ratio: 1"></div>
        </div>
        <input
            class="selection__input"
            type="text"
            autocomplete="off"
            v-model="input"
            :name="id"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :aria-label="placeholder"
            @focus="scrollMenuCollapse()"
            @input="inputFunction()"
            @keyup.down="mathSelectIndex(1)"
            @keyup.up="mathSelectIndex(-1)"
            @keyup.enter="getItem()"
        />
        <template v-if="isSelectForm">
            <div
                ref="dropdownElement"
                class="selection__dropdown"
                uk-dropdown="
                    mode: click;
                    animation: slide-top;
                    offset: 0px;
                    animate-out: true;
                "
            >
                <ol class="scrollbar scrollbar-rounded">
                    <li
                        v-for="(item, index) in filteredData"
                        :key="item.id"
                        :ref="(el) => liElements.push(el)"
                        :class="['dropdown__item', 'link', 'link-blue', { 'is-active': index == selectedIndex }]"
                        @click="choiceItem(item)"
                        @mouseover="selectedIndex = index"
                    >
                        {{ item.title }}
                    </li>
                    <li v-if="!filteredData.length" class="selection-empty">Ничего не найдено</li>
                </ol>
            </div>
            <font-awesome-icon icon="fa-chevron-down" />
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onUpdated, nextTick } from "vue";
import { fetchData } from "@/utils";
import UIkit from "uikit";

const PROPS = defineProps({
    placeholder: null,
    id: null,
    api: null,
    value: null,
    requiredInputs: null,
    isDisabled: { default: true },
    defaultList: { default: [] },
    isSelectForm: { default: false },
});

const emit = defineEmits(["get:value", "get:items", "action:send"]);

const response = ref([]);
const input = ref(PROPS.value);
const inputAction = ref();
const dropdownElement = ref();
const liElements = ref([]);
const selectionElement  = ref();
const selectedIndex = ref(-1);

const isLoading = ref(false);

const filteredData = computed(() => {
    if (list.value != undefined && inputAction.value) {
        return list.value.filter((option) => {
            return option.title.toString().toLowerCase().indexOf(input.value.toLowerCase()) >= 0;
        });
    }
    return list.value;
});

const list = computed(() => {
    return PROPS.defaultList.concat(response.value);
});

const api = computed(() => {
    return `select/${PROPS.api}/${PROPS.id}?${PROPS.requiredInputs.map((i) => i.id + "=" + i.value).join("&")}`;
});

const inputFunction = () => {
    liElements.value = [];
    const value = PROPS.isSelectForm ? (!!input.value ? PROPS.value : null) : input.value;
    setInputValue(value, input.value);
    inputAction.value = input.value;
    UIkit.dropdown(dropdownElement.value).show();
};

const getInitData = async () => {
    isLoading.value = true;
    setInputResponse(await fetchData(api.value));
    isLoading.value = false;
};

const choiceItem = (item) => {
    const { id, title } = item || {};
    setInputValue(id, title);
    UIkit.dropdown(dropdownElement.value).hide(0);
};

const setInputValue = (value, input_v = null) => {
    input.value = input_v;
    inputAction.value = undefined;
    emit("get:value", value);
    //value != null ? cookies.set(PROPS.id, value) : cookies.remove(PROPS.id);
    cacheValue = value;
};

const setInputResponse = (value = new Array()) => {
    liElements.value = [];
    selectedIndex.value = -1;
    emit("get:items", (response.value = value));
};

const findInputValue = (valueId) => {
    return list.value.find(({ id }) => (id == valueId));
}; 

const setInitData = (value) => {
    choiceItem(findInputValue(value));
};

if (!PROPS.isDisabled && PROPS.isSelectForm) {
    getInitData();
}

let cacheSavedAPI = api.value;
let cacheValue = null;

onUpdated(() => {
    if (PROPS.isSelectForm && api.value !== cacheSavedAPI) {
        cacheSavedAPI = api.value;
        setInputResponse();

        nextTick(async () => {
            // Если значение изменено на null, очищаем значения, иначе получаем новыый список response.
            if (PROPS.isDisabled === false) {
                await getInitData();

                if (findInputValue(PROPS.value)) {
                    return;
                }
            }
            setInputValue();
        });
    }

    // Устанавливаем значение по умолчанию, если оно задано в массиве input + есть в списке response.
    if (PROPS.value !== cacheValue) {
        if (findInputValue(PROPS.value)) {
            setInitData(PROPS.value);
        }
    }
});

const scrollMenuCollapse = () => {
    const menu = document.querySelector(".menu");
    const offset = selectionElement.value.offsetTop;

    menu.style.scrollBehavior = "smooth";
    menu.scrollTop = offset;
    setTimeout(() => {
        UIkit.dropdown(dropdownElement.value).show();
    }, 180);
    return void 0;
};

const mathSelectIndex = (value) => {
    if (PROPS.isSelectForm) {
        value = value < 0 ? selectedIndex.value - 1 : selectedIndex.value + 1;

        if (value >= 0 && value < filteredData.value.length) {
            const selectedElement = liElements.value[value];
            const parent = selectedElement.closest("ol");

            if (selectedElement.offsetTop < parent.scrollTop) parent.scrollTop -= 30;

            if (selectedElement.offsetTop > parent.scrollTop + 200) parent.scrollTop += 30;

            selectedIndex.value = value;
        }
    }
};

const getItem = () => {
    if (PROPS.isSelectForm) {
        let selectedItem = undefined;
        if (selectedIndex.value > -1) {
            selectedItem = filteredData.value[selectedIndex.value];
            selectedIndex.value = -1;
        } else {
            selectedItem = filteredData.value[0];
        }
        choiceItem(selectedItem);
    } else {
        emit("action:send", true);
    }
}
</script>

<style lang="sass" scoped>
ol, ul
    padding: 0
</style>
