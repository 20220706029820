<template>
    <footer class="footer">
        <div class="container footer--container">
            <div class="footer--content">
                <router-link class="footer--logo" to="/" aria-label="Back to Home">
                    <LogoDefault />
                </router-link>
                <p class="footer--copyright">© Avantech Parts Group Co., Ltd, {{ new Date().getFullYear() }} All rights reserved.</p>
                <a class="footer--feedback" href="#feedback" @click.prevent="store.commit('modal/CALL_MODAL', 'feedback')">
                    {{ $t("modalFeedback") }}
                </a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import LogoDefault from "@assets/img/svg/logo.svg";
import { useStore } from "vuex";

const store = useStore();
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.footer
    grid-area: f
    max-height: 100px
    display: table
    position: sticky
    top: 100%
    max-width: 100vw
    background: white
    &--logo
        width: 175px
        height: 32px
        transition: .15s ease-in-out
        position: relative
        @include lg
            grid-row: 1
            grid-column: 1
        @include md
            grid-column: 2
            width: 150px
        > svg
            width: 100%
            height: 100%
            position: absolute
        &:hover
            opacity: .65
    &--content
        display: grid
        grid-template-columns: 1fr 10fr 1fr
        align-items: center
        padding: 60px 0
        border-top: 1px solid #eee
        @include lg
            grid-template-columns: 1fr auto
            grid-template-rows: auto auto
            padding: 40px 0 20px
        > a
            white-space: nowrap
            font-size: 1.125rem
            font-weight: 700
            color: $color-grey-500
            border-bottom: 1px solid transparent
            line-height: 100%
    &--copyright
        margin: 0 40px
        color: $color-grey-300
        @include lg
            margin: 10px 0
            grid-row: 2
            grid-column: 1
        @include md
            grid-row: 3
            grid-column: 1/3
    &--feedback
        width: fit-content
        @include lg
            grid-column: 2
            grid-row: 1/3
            padding-bottom: 5px
        @include md
            grid-column: 1/3
            grid-row: 2
            margin: 20px 0 0
        &:hover
            border-bottom: 1px solid 
</style>
