<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { locale, t } = useI18n();
const route = useRoute();
const router = useRouter();

const props = defineProps({
    name: String,
});

const isActive = computed(() => {
    return locale.value === props.name.toLowerCase();
});

function updateLocale(lang) {
    localStorage.setItem("locale", lang);
    window.location.href = router.resolve({
        name: route.name,
        query: route.query,
        hash: route.hash,
        params: { locale: lang },
    }).fullPath;

    if (route.meta.titleI18n && Object.keys(route.params).length == 0) {
        document.title = t(route.meta.titleI18n);
    }
}
</script>

<template>
    <div class="language--item" :class="{ 'language--item-active': isActive }" @click="updateLocale(name.toLowerCase())">
        {{ name }}
    </div>
</template>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.language--item
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    width: 32px
    aspect-ratio: 1.2/1
    background: $color-white-100
    color: $font-color
    transition: 0.15s ease-in-out
    border: 1px solid
    // font-family: $font-europe
    font-style: normal
    font-weight: normal
    font-size: 12px
    letter-spacing: 0.05em
    @include md 
        width: 40px
        aspect-ratio: 1.4/1
    &-active
        font-weight: bold
        background: $color-primary
        color: $color-white-100
        border: 1px solid $color-primary
        box-shadow: 0 0 0px 2px rgb(22 73 148 / 25%)
    &:first-child
        border-radius: 5px 0 0 5px
        border-right: none
    &:last-child
        border-radius: 0 5px 5px 0
        border-left: none
    &:hover:not(.language--item-active)
        background: $color-primary
        border-color: $color-primary
        color: white
</style>
