export const kr = {
    /** components */
    buttonMore: '더',
    buttonMarketplace: '마켓플레이스에서 구매',
    /** end components */
    /** menu */
    menu: 'Menu',
    menuMain: '본토',
    menuHome: "Avantech Parts Group",
    menuCatalog: "목록",
    menuInfo: "정보",
    menuWhereToBuy: "구입처",
    menuAssurance: "보증",
    menuPartners: "트너",
    menuAbout: "회사 소개",
    menuSelection: "부품 찾기",
    /** end menu */
    /** modal feedback marketplace */
    modalFeedback: "피드백",
    marketplaceTitle: "마켓플레이스 선택",
    marketplaceLinkRetail: "지도상의 소매점",
    /** end modal */
    /** avantech-preview */
    previewText: '신뢰할<br />수 있는<br />부품',
    /** category item */
    categorySteering: "서스펜션 및 <br>조향장치 파트",
    categorySteeringMobile: "서스펜션 및 <br>조향장치 <br>파트",
    categoryLamp: "램프는",
    categoryBelts: "의 구동벨트",
    categoryAnthers: "가루잠금장치",
    categoryRubber: "고무제 파트",
    categoryPumps: "연료 펌프",
    categoryFilters: "필터",
    categoryBrakes: "브레이크 <br/>시스템",
    categoryWipers: "와이퍼 블레이드",
    categoryWipersMobile: "와이퍼 블레이드",
    /** end category item */
    /** about-preview */
    aboutPreview: `<p>자동차 애프터마켓의 글로벌 공급업체</p>
        AVANTECH 제품은 글로벌 자동차 제조업체의 조립 라인, 소매업체 및 기술 업체에 공급됩니다.
        서비스. Avantech의 절대적으로 모든 세부 사항을 생산하기 위해 높은 수준을 제공하는 고급 기술이 사용됩니다.
        세계의 품질과 신뢰.`,
    aboutButton: "회사 소개",
    /** end about-preview */
    /** main-slogan */
    homeSlogan1: "품질",
    homeSlogan2: "수용 가능한",
    homeSlogan3: "가격",
    homeSlogan4: "편의",
    disclaimerSlogan:
        "많은 구매자는 상품의 품질을 저하시키지 않으면서 가격을 낮출 수 있음을 이해합니다(국가마다 인건비 및 비즈니스 조건이 다르기 때문).",
    disclaimerSloganSpan: "AVANTECH 제품의 품질은 지속적으로 모니터링됩니다.",
    /** end main-slogan */
    /** postcard */
    postcardTitle: "우리는 고객의 소리에 귀를 기울입니다.",
    postcardText: `<p>제품에 대한 의견을 공유하고 어떤 어려움이 있었는지 알려주시면 개선하거나 저희에 대해 배울 수 있습니다.</p>
        추신 - 예비 부품을 찾을 수 없는 경우 화면 상단의 상담원을 통해 선택 서비스를 이용할 수 있습니다.`,
    postcardFrom: "Avantech 팀<br/>이 귀하를 위해 일합니다",
    /** end postcard */
    /** store */
    storeRefText: '온라인 또는 해당 도시의 매장에서 제품 구매',
    storeRefLink: '지도에서',
    /** CarouselProduct.vue */
    categorySteeringNote: "공. 스티어링 팁. 타이로드.",
    categorySteeringText: "AVANTECH 제품의 출현으로 자동차 소유자는 높은 OEM 요구 사항 및 표준을 충족하는 서스펜션 및 스티어링 부품에 대해 초과 지불하지 않을 수 있습니다. Avantech Parts Group은 말레이시아와 대만의 예비 부품 제조 공장에서 부품을 제조합니다. 이 공장의 컨베이어에서는 세계 브랜드의 오리지널 부품과 아날로그 부품이 모두 생산됩니다.",
    categoryLampNote: "높은 온도. 안개. 할로겐.",
    categoryLampText: "현대적인 로봇 생산 방식으로 한국에서 독점적으로 생산됩니다. 모든 AVANTECH 램프는 필라멘트 기하학 테스트, 가스 누출 테스트 및 진동 테스트를 통과합니다.",
    categoryBeltsNote: "쐐기. 폴리클리닉.",
    categoryBeltsText: "구동 벨트는 크랭크축 풀리에서 토크를 전달하여 ICE 부착물과 냉각수 펌프를 구동하도록 설계되었습니다. Avantech Parts Group은 한국의 예비 부품 제조 공장에서 AVANTECH 브랜드로 제품을 제조합니다.",
    categoryAnthersNote: "",
    categoryAnthersText: "AVANTECH 꽃밥 생산에는 고밀도 네오프렌이 사용됩니다. 대만과 말레이시아의 정품 부품과 아날로그 부품을 모두 생산하는 예비 부품 제조 공장에서 제조됩니다. 생산은 국제 품질 표준 ISO/TS 16949를 준수합니다.",
    categoryRubberNote: "사일런트 블록. 안티 롤 바 부싱.",
    categoryRubberText: "AVANTECH 고무 제품은 저온의 거친 러시아 기후에서 잘 입증되었습니다. 이러한 부품은 대만과 말레이시아에 위치한 예비 부품 제조 공장에서 제조됩니다. 이 공장의 컨베이어에서는 세계 브랜드의 오리지널 부품과 아날로그 부품이 모두 생산됩니다. 러시아 연구소에서 AVANTECH 제품은 찢어짐, 비틀림, 변위에 대한 모든 테스트를 성공적으로 통과하여 국제 품질 표준 준수를 보장할 수 있습니다.",
    categoryPumpsNote: "",
    categoryPumpsText: "전기 연료 펌프 생산을 위한 Avantech Parts Group의 핵심 파트너는 한국 자동차 연료 장비의 선두 제조업체 중 하나입니다. 공장의 제품은 세계 자동차 회사의 조립 라인에도 공급됩니다. AVANTECH 연료 펌프는 정확한 OEM 사양으로 제조됩니다. 생산 공정은 ISO/TS16949:2002 표준에 따라 인증되었습니다.",
    categoryFiltersNote: "기름. 공기. 가게. 연료.",
    categoryFiltersText: "엔진의 깨끗한 오일과 공기, 실내 먼지 및 불쾌한 냄새의 부재 - 이것이 AVANTECH 필터가 성공적으로 수행하는 주요 작업입니다. 수십만 명의 운전자가 이미 고품질, 가장 넓은 범위 및 매력적인 가격에 감사했습니다. AVANTECH는 일본, 한국, 유럽 및 미국 차량을 위한 다양한 필터를 제공합니다.", 
    categoryBrakesNote: "브레이크 패드. 브레이크 디스크. 천공된 브레이크 디스크.",
    categoryBrakesText: "AVANTECH 브레이크 패드는 HYUNDAI, KIA MOTORS, RENAULT SAMSUNG, FUSO를 포함한 최대 자동차 제조업체의 컨베이어 OEM 공급업체인 한국의 현대식 제조 공장에서 제조됩니다. <br/><br/>AVANTECH 브레이크 디스크는 대만에 있는 파트너 공장에서 제조됩니다. OEM 제조업체와의 장기적인 협력은 이 공장 제품의 품질 수준을 확인하고 파트너 공장이 세계 최고의 자동차 제조업체(Toyota, Honda, Nissan)에 순정 예비 부품을 공급할 수 있는 자격을 부여합니다.",
    categoryWipersNote: "잡종. 프레임리스. 액자.",
    categoryWipersText: "AVANTECH 와이퍼 블레이드는 여름, 겨울 및 사계절 작동을 위해 설계된 모든 유형의 디자인으로 대표됩니다. 누구나 국산차와 국산차 모두에 적합한 모델을 선택할 수 있습니다.",
    /** About.vue */
    aboutDescription: "Avantech Parts Group은 자동차 애프터마켓의 글로벌 공급업체입니다. 당사의 제품은 글로벌 자동차 제조업체, 소매업체 및 주유소의 조립 라인에 공급됩니다. AVANTECH의 모든 부품 생산에는 전 세계적으로 높은 수준의 품질과 신뢰를 제공하는 첨단 기술이 사용됩니다.",
    aboutQualityTitle: "제품 품질",
    aboutQualityValue: "Avantech Parts Group의 제품은 한국 자동차 산업의 엄격한 OE 표준 및 품질 표준을 충족합니다. 동일한 기본 관리 원칙을 통해 애프터마켓의 분위기를 설정할 수 있습니다.",
    aboutResponsableTitle: "우리의 책임",
    aboutResponsableValue: "어떤 조건에서도 차량의 안전한 작동을 보장합니다. Avantech Parts Group의 품질 관리 시스템은 OEM 수준의 아날로그에 비해 AVANTECH 예비 부품의 신뢰성과 서비스 수명을 증가시킵니다. 이것은 부품의 수명과 차량의 서비스 간격을 늘리고 운전 편의성을 향상시킵니다.",
    aboutRangeTitle: "넓은 범위의",
    aboutRangeValue: "우리의 성공은 Avantech Parts Group이 소비자의 요구와 기대를 충족하는 여러 지역에서 대표되는 대부분의 차량에 대한 다양한 예비 부품을 제공한다는 사실에 기인합니다.",
    aboutHistory: "역사",
    about2005: "한국에서는 고품질의 한국 예비 부품을 구매자에게 적합하고 경쟁력 있는 가격으로 시장에 출시하기 위한 프로젝트가 시작되었습니다. 세계 자동차 브랜드의 컨베이어 예비 부품 제조업체인 다수의 한국 기업이 이 프로젝트에 참여했습니다.",
    about2006: "Avantech Parts Group은 성공적인 마케팅 연구와 높은 제품 경쟁력으로 프로젝트의 승자가 됩니다.<br><br>러시아에서 AVANTECH 제품 판매 시작. 가장 먼저 판매된 제품은 와이퍼 블레이드, CV 조인트용 꽃밥 및 스티어링 랙이었습니다.",
    about2009: "제품군 확장, AVANTECH 공기 및 오일 필터가 판매되었습니다.",
    about2010: "유럽 자동차용 자동차 부품 범위 개발.<br><br>드라이브 벨트, 브레이크 패드와 같은 새로운 자동차 부품 그룹의 판매 시작.",
    about2012: "CIS 국가에서 AVANTECH 제품 판매 시작.<br><br>서스펜션 스프링, 오일 및 캐빈 필터와 같은 새로운 자동차 부품 그룹 시장 진출.",
    about2014: "제품 범위 확장: 섀시 부품, 고전압 전선, 브레이크 디스크.",
    about2015: "러시아에서 AVANTECH 보증 프로그램이 시작되었습니다.<br><br>새로운 자동차 부품 그룹 판매 시작 - 서스펜션 충격 흡수 장치; 점화 코일 및 AVANTECH 연료 펌프.",
    about2017: "제품 범위 확장, AVANTECH 램프 판매 시작.",
    aboutBrandPanel: "상표",
    aboutBrandValue: "AVANTECH 브랜드의 소유자는 대한민국의 수도인 서울에 본사를 둔 제조 회사인 Avantech Parts Group Co., Ltd입니다.",
    aboutProductionTitle: "생산",
    aboutProductionValue: "2006년부터 Avantech Parts Group은 현대적인 생산 시설과 기술 노하우를 사용하여 자동차 거대 기업의 컨베이어용 순정 부품을 생산하는 공장에서 AVANTECH 브랜드로 제품의 상당 부분을 제조해 왔습니다. 생산 지역은 이에 국한되지 않습니다. 회사의 공장은 말레이시아, 인도네시아, 대만 및 기타 아시아 및 남미 국가에도 있습니다.",
    aboutTechTitle: "하이테크",
    aboutTechValue: "AVANTECH 제품은 순정 예비 부품에 필요한 완벽한 품질 표준에 따라 제조됩니다. AVANTECH 브랜드의 슬로건인 첨단 기술은 일본, 한국 및 유럽의 광범위한 자동차용 예비 부품의 개발, 생산 및 유통에 있어 첨단 솔루션에 중점을 두고 있음을 강조합니다. 최종 소비자를 위한 AVANTECH 브랜드의 소비자 가치는 매력적인 가격 수준과 결합된 고품질의 보증에 있습니다.",
    aboutWorldWeb: "월드 네트워크",
    aboutFactories: "Avantech Parts Group 공장은 한국, 인도네시아, 말레이시아, 대만 및 기타 아시아 및 남미 국가에 위치하고 있습니다.",
    aboutProductTitle: "주요 상품",
    aboutProductValue: "공기 및 오일 필터, 섀시 부품, 전기 장비, 엔진 부품, 브레이크 시스템 부품, 와이퍼 블레이드는 Avantech Parts Group의 핵심 제품입니다.",
    aboutPartnersTitle: "회사 파트너",
    aboutPartnersValue: "Avantech Parts Group의 주요 파트너는 KIA 및 HYUNDAI의 한국 제품 공급업체로, 예비 부품의 글로벌 애프터마켓에서 활발히 활동하고 있으며 한국 내수 시장에서 해당 부문의 1위를 차지하고 있습니다."
};
