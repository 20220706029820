<template>
    <ul class="uk-navbar-nav navbar-menu" :class="{'navbar-menu-row': row}">
        <li v-for="item in menu" :key="item.id" :class="['navbar-menu--li', { 'navbar-menu--li-active': item.active }]">
            <router-link :to="`/${locale}${item.link}`" :class="{ 'router-link-active':  item.active }">
                {{ $t(item["i18n-code"]) }}
            </router-link>
        </li>
    </ul>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
const regex = new RegExp(locale.value);

defineProps({ row: false });

const menu = computed(() => {
    return useStore().state.static.menu.filter((item) => regex.test(item["i18n-langs"]));
});
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.navbar-menu
    &--li
        margin: 0 8px
        a
            font-style: normal
            font-weight: 400
            font-size: 14px
            letter-spacing: 0.1em
            text-transform: none
            color: $font-color
            font-family: $font-primary
            display: inline
            border-bottom: 1px solid transparent
            transition: .1s ease-in-out
            &:hover
                color: $color-primary
                border-bottom: 1px solid
            @include lg
                min-height: 48px
            @include md
                
            .navbar-menu-light &:hover
                color: white
    &-row
        display: flex
        flex-direction: column
    &-light
        li > a
            color: white
            font-size: 20px
            display: block
            font-weight: 500
            @include md
                display: inline-block
                font-weight: 500
                min-height: auto
                margin-bottom: 20px
    @include xl
        gap: 15px

    &:not(.navbar-menu-light):deep(.router-link-active)
        color: $color-primary
        font-weight: 700
</style>
