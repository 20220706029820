import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createRouter, createWebHistory } from "vue-router";
import { createStore } from "./storage";
import { routes, funcBeforeResolve } from "./routes.js";
import { messages, langs } from "./locales";
import { VueReCaptcha } from "vue3-usf-feedback-form";
import clickOutside from "click-outside-vue3";
// import cookiesPlug from "vue-cookies";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronDown);
library.add(faBars);
library.add(faXmark);

import App from "./App.vue";
import smoothscroll from "smoothscroll-polyfill";
import "@assets/sass/style.sass";

const app = createApp(App);
smoothscroll.polyfill();

const i18n = createI18n({
    locale: localStorage.getItem("locale") || "ru",
    fallbackLocale: "en",
    legacy: false,
    warnHtmlMessage: false,
    messages,
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const dependencies = [
    router,
    i18n,
    createStore,
    clickOutside,
    // cookiesPlug,
    {
        pack: VueReCaptcha,
        params: {
            siteKey: import.meta.env.VITE_APP_CAPTCHA_SITE_KEY,
        },
    },
];

dependencies.forEach((item) => {
    if (item.pack === undefined) {
        app.use(item);
    } else {
        app.use(item.pack, item.params);
    }
});

router.beforeResolve(async (to, from, next) => funcBeforeResolve(to, from, next, langs, i18n));

import { initYandexMetrika } from 'yandex-metrika-vue3';  
if (!!import.meta.env.VITE_APP_METRIC_YANDEX) {
    //const { initYandexMetrika } = await import("yandex-metrika-vue3");
    app.use(initYandexMetrika, {
        id: import.meta.env.VITE_APP_METRIC_YANDEX,
        env: import.meta.env.VITE_APP_ENV,
        router
    });
}

if (!!import.meta.env.VITE_APP_METRIC_GOOGLE) {
    const { googleAnalytics } = await import("vue-analytics");

    app.use(googleAnalytics, {
        id: import.meta.env.VITE_APP_METRIC_GOOGLE,
        disabled: import.meta.env.VITE_APP_ENV !== "production",
        router,
    });
}

app.directive("scroll", {
    beforeMount: function (el, binding) {
        const glob = binding.arg === "el" ? el : window;
        let func = function (evt) {
            if ((el.id && null == document.getElementById(el.id)) || binding.value(evt, el)) {
                glob.removeEventListener("scroll", func);
            }
        };
        glob.addEventListener("scroll", func);
    },
})
.component("font-awesome-icon", FontAwesomeIcon)
.mount("#app");
