<template>
    <div :class="['menu-hamb', { 'menu-open': toggle && enabled, 'menu-toggle': launch }]" @click="funcToggle(!toggle)">
        <div class="hamburger">
            <span class="hamburger--path path--1"></span>
            <span class="hamburger--path path--2"></span>
            <span class="hamburger--path path--3"></span>
        </div>
        <div class="cross">
            <span class="hamburger--path cross--1"></span>
            <span class="hamburger--path cross--2"></span>
        </div>
        <div class="menu-text">
            {{ $t("menu") }}
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
    toggle: {
        type: Boolean,
        default: false,
    },
    enabled: {
        type: Boolean,
        default: true,
    },
    launch: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["get:toggle"]);
const toggle = ref(props.toggle);
const launch = ref(props.launch);

const funcToggle = function (value) {
    toggle.value = value;
    emit("get:toggle", value);
};

watch(() => props.toggle,
    (value) => {
        if (toggle.value != value) {
            funcToggle(value);
        }
    }
);

watch(() => props.launch,
    (value) => {
        launch.value = value;
    }
);
</script>

<style lang="sass" scoped>
@import '@stylebase/variables.sass'
@import '@stylebase/media.sass'
.menu-hamb
    width: 100px
    height: 100px
    margin: -10px auto
    position: relative
    cursor: pointer
    border-radius: 5px
    //transform: scale(.82) translateY(-10px)
    transform: scale(.75)
    transition: .15s ease
    margin-left: -5px
    @include sm
        margin-right: -15px
    &:hover
        //transform: scale(.82) translateY(-10px)
        transform: scale(.75)
    & *
        transition: .15s ease
        box-sizing: border-box
    .hamburger
        position: absolute
        height: 100%
        width: 100%
        span
            width: 0
            height: 4px
            position: relative
            top: 24px
            left: 20px
            margin: 10px 0
    span
        display: block
        background: $color-primary
        border-radius: 2px
    &--light span
        background: white
    .menu-text
        // bottom: 5px
        // position: absolute
        // left: 50%
        // transform: translateX(-50%)
        // font-family: $font-primary
        // font-weight: 700
        // font-size: 14px
        // line-height: 17px
        // text-align: center
        // letter-spacing: 0.15em
        // color: $color-primary
        // opacity: 0
        display: none

.menu-hamb.menu-toggle
    .hamburger
        span
            width: 60px
            height: 4px
            position: relative
            top: 24px
            left: 20px
            margin: 10px 0
            &:nth-child(1)
                transition-delay: .5s
            &:nth-child(2)
                transition-delay: .625s
            &:nth-child(3)
                width: 30px
                transition-delay: .75s
    .menu-text
        opacity: 1
        transition-delay: .75s

    .cross
        position: absolute
        height: 100%
        width: 100%
        transform: rotate(45deg)
        span
            &:nth-child(1)
                height: 0%
                width: 4px
                position: absolute
                top: 25%
                left: 48px
                transition-delay: 0s
            &:nth-child(2)
                width: 0%
                height: 4px
                position: absolute
                top: 48px
                left: 25%
                transition-delay: .15s
.menu-hamb.menu-open
    .menu-text
        opacity: 0
        transition-delay: 0s
    .hamburger
        span
            width: 0 !important
            &:nth-child(1)
                transition-delay: 0s
            &:nth-child(2)
                transition-delay: .125s
            &:nth-child(3)
                transition-delay: .25s
    .cross
        span
            &:nth-child(1)
                height: 50%
                transition-delay: .625s
            &:nth-child(2)
                width: 50%
                transition-delay: .375s
</style>
